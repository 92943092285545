import { FC } from "react"
import { createHashRouter, createRoutesFromElements, RouterProvider, Route, Navigate } from "react-router-dom"
import { message } from "antd"

import { ErrorPage } from "./components/template/error"
import { Page } from "./components/template"
import { Questions } from "./pages/questions"
import { ExamBuilder } from "./pages/examBuilder"
import { ForgotPassword } from "./pages/forgotPassword"
import { Me } from "./pages/me"
import { Progress } from "./pages/progress"
import { Usage } from "./pages/usage"
import { SignIn } from "./pages/signIn"
import { SignUp } from "./pages/signUp"
import { Messages } from "./pages/messages"
import { Pastpapers } from "./pages/pastpapers"

import { getTags } from "./utils/apis"
import { QuestionBank } from "./types/query.d"

import type { Params } from "react-router-dom"

import "antd/dist/reset.css"
import "./App.scss"

const loadPastpaperTags = async () => {
  return { exams: await getTags({ questionBank: QuestionBank.Pastpaper }) }
}

const loadTags = async ({ params }: { params: Params }) => {
  const { questionBank } = params
  switch (questionBank) {
    case "0": {
      return { questionBank: QuestionBank.Topical, exams: await getTags({ questionBank: QuestionBank.Topical }) }
    }
    case "1": {
      return { questionBank: QuestionBank.Special, exams: await getTags({ questionBank: QuestionBank.Special }) }
    }
    default: {
      return { questionBank: undefined, exams: undefined }
    }
  }
}

const loadTagsForTopical = async () => {
  return { questionBank: QuestionBank.Topical, exams: await getTags({ questionBank: QuestionBank.Topical }) }
}

const App: FC = () => {
  const [messageApi, contextHolder] = message.useMessage()
  let router = createHashRouter(
    createRoutesFromElements(
      <Route path="/" element={<Page messageApi={messageApi} contextHolder={contextHolder} />} errorElement={<ErrorPage />}>
        <Route index element={<Navigate to="/questions/0" replace />} />
        <Route path="/forgot" element={<ForgotPassword messageApi={messageApi} />} />
        <Route path="/signIn" element={<SignIn messageApi={messageApi} />} />
        <Route path="/signUp" element={<SignUp messageApi={messageApi} />} />
        <Route path="/me" loader={loadTagsForTopical} element={<Me messageApi={messageApi} />} />
        <Route path="/usage" element={<Usage messageApi={messageApi} />} />
        <Route path="/progress" element={<Progress messageApi={messageApi} />} />
        <Route path="/messages" element={<Messages messageApi={messageApi} />} />
        <Route path="/pastpapers" loader={loadPastpaperTags} element={<Pastpapers messageApi={messageApi} />} />
        <Route path="/questions/:questionBank" loader={loadTags} element={<Questions messageApi={messageApi} />} />
        <Route path="/builder/:questionBank" loader={loadTags} element={<ExamBuilder messageApi={messageApi} />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    )
  )
  return <RouterProvider router={router} />
}

export default App
