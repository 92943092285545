import { Dispatch, FC, ReactElement, SetStateAction, useEffect, useState } from "react"
import { Button, Checkbox, Layout, List, Modal, Row, Space, Select, Radio, Col } from "antd"
import { isMobile } from "react-device-detect"
import { DownOutlined, UpOutlined } from "@ant-design/icons"
import { useLoaderData, useNavigate } from "react-router-dom"

import { ErrorPage } from "../../components/template/error"
import { Question } from "../../components/question"
import { QuestionList } from "../../components/questionList"
import { Selector } from "../../components/selector"

import { OrderBy, getQuestionById, getQuestions, isAuthenciacted, firstSignInCheck, setQuestionLabel, OrderType } from "../../utils/apis"
import { PageSize } from "../../utils/constants"

import "./index.scss"
import { MessageInstance } from "antd/es/message/interface"
import { SizeType } from "antd/es/config-provider/SizeContext"
import { QuestionBank, Query } from "../../types/query.d"

const OrderByButton: FC<{
  field: string
  orderBy: OrderBy
  setOrderBy: Dispatch<SetStateAction<OrderBy>>
}> = ({ field, orderBy, setOrderBy }) => {
  const myField = `properties.${field}`

  const handleClick = () => {
    const nextOrderType = orderBy?.orderType === OrderType.Assending ? OrderType.Desending : OrderType.Assending
    const nextOrderBy: OrderBy = { field: myField, orderType: nextOrderType }
    setOrderBy(nextOrderBy)
  }

  return (
    <Button style={{ width: "90px" }} onClick={handleClick}>
      <Space size="small">
        {field[0].toUpperCase() + field.slice(1)}
        {orderBy.field === `properties.${field}` ? (
          orderBy.orderType === 1 ? (
            <UpOutlined />
          ) : (
            <DownOutlined />
          )
        ) : (
          <></>
        )}
      </Space>
    </Button>
  )
}

const MaxQuestionListLength = 20

const Questions: FC<{ messageApi: MessageInstance }> = ({ messageApi }) => {
  const [myExams, setMyExams] = useState<Exams>([])
  const [useMyExams, setUseMyExams] = useState<boolean>(true)
  const [mySubjects, setMySubjects] = useState<string[]>([])
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ])
  const [smallScreen, setSmallScreen] = useState<boolean>(false)
  const [buttonSize, setButtonSize] = useState<SizeType>("middle")

  useEffect(() => {
    setSmallScreen(windowSize[0] < 1000)
    setButtonSize(windowSize[0] < 1000 ? "small" : "middle")
  }, [windowSize])

  // console.debug(myExams, mySubjects)
  const navigate = useNavigate()
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight])
    }

    window.addEventListener('resize', handleWindowResize)
    firstSignInCheck(navigate)
      .then(({ subjects }) => {
        // console.log("questions", subjects);
        setMySubjects(subjects.map(([, subject]) => subject))
      }
      )
      .catch((err) => console.error(err))
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [navigate])

  const { exams, questionBank } = useLoaderData() as { exams: Exams; questionBank: QuestionBank }
  const [questions, setQuestions] = useState<Question[]>([])
  const [open, setOpen] = useState(false)
  const [showAnswer, setShowAnswer] = useState<boolean>(false)
  const [showQuestion, setShowQuestion] = useState<boolean>(false)
  const [page, setPage] = useState<number>(1)
  const [examId, setExamId] = useState<string>()
  const [subject, setSubject] = useState<string>()
  const [label, setLabel] = useState<number>(0)
  const [orderBy, setOrderBy] = useState<OrderBy>({ field: "properties.year", orderType: OrderType.Desending })
  const [properties, setProperties] = useState<{ [k: string]: any }>({})
  const [question, setQuestion] = useState<Question>()
  const [total, setTotal] = useState<number>(0)
  const [questionList, setQuestionList] = useState<Question[]>([])
  const [qidOnLoading, setQidOnLoading] = useState<string>()

  const updateLabelOfGivenQuestion = (questionId: string, label: number) => {
    // console.debug("updateLabelOfGivenQuestion", questionId, label)
    setQuestions(original => original.map(q => (q._id === questionId) ? { ...q, label } : q))
  }

  useEffect(() => {
    const myExams = exams
      .map(exam => {
        const { subjects = [] } = exam
        return {
          ...exam,
          subjects: subjects.filter(({ _id }) => mySubjects.includes(_id as string))
        }
      })
      .filter(({ subjects }) => subjects.length > 0)

    // console.log(myExams)
    setMyExams(myExams)
  }, [mySubjects, exams])

  const [loading, setLoading] = useState<boolean>(false)
  const [loadingAnswer, setLoadingAnswer] = useState<boolean>(false)
  const [answerLoaded, setAnswerLoaded] = useState<boolean>(false)

  const questionInList: boolean = question !== undefined && questionList.includes(question)

  const questionHasAnswer: boolean =
    question && questions.some((q) => q._id === question._id)
      ? questions
        .find((q) => q._id === question._id)!
        .blocks.some((b) => b.type === "textAnswer" || b.type === "imageAnswer")
      : false

  const handleQuery = (q: Query) => {
    // console.log(q)
    const { examId, subjectId, properties } = q
    // assign exam, subject and properties from
    if (examId !== "") {
      setExamId(examId)
    }
    if (subjectId !== "") {
      setSubject(subjectId)
    }
    setProperties(Object.fromEntries(Object.entries(properties).map(([n, v]) => [n, v])))

    setPage(1)
  }

  const appendToQuestionList = (question: Question | undefined) => {
    if (questionList.length >= MaxQuestionListLength) {
      messageApi.warning(`You can only add ${MaxQuestionListLength} questions at most to generate PDF.`)
      return
    }
    if (question && !questionList.includes(question)) {
      setQuestionList((prev) => {
        return [...prev, question]
      })
    }
  }

  const removeFromQuestionList = (question: Question | undefined) => {
    if (question && questionList.includes(question)) {
      setQuestionList((prev) => {
        return prev.filter((qid) => qid !== question)
      })
    }
  }

  const handleShowAnswer = () => {
    if (!isAuthenciacted()) {
      messageApi.error({ content: "Unauthorized access to view answer, please sign in.", duration: 3 })
      return
    };

    if (!answerLoaded) {
      const key = Date.now()
      setLoadingAnswer(true)
      messageApi.open({
        key,
        type: "loading",
        content: "Loading answer...",
        duration: 0
      })
      getQuestionById(questionBank, question?._id!).then(({ result }) => {
        // console.debug(question?._id)
        const { success, data } = result
        const { _id, label } = data
        // console.debug(result)
        if (success) {
          messageApi.open({
            key,
            type: "success",
            content: "Answer loaded.",
            duration: 2,
          })
          setLoadingAnswer(false)
          // setQuestion(data)
          updateLabelOfGivenQuestion(_id, label)
          setAnswerLoaded(true)
        } else {
          messageApi.open({
            key,
            type: "error",
            content: "Failed to load answer.",
            duration: 2,
          })
          setLoadingAnswer(false)
          setAnswerLoaded(true)
        }
      }).catch(err => {
        messageApi.open({
          key,
          type: "error",
          content: "Failed to load answer.",
          duration: 2,
        })
      })
    }

    setShowAnswer(true)
  }

  const modalButtons: ReactElement[] = [
    ...(isAuthenciacted() && question && question.label >= 0) ?
      [<Select
        key="label"
        value={question.label}
        onChange={(value) => {
          setLoading(true)
          setQuestionLabel({ questionBank, questionId: question._id, label: value })
            .then(({ result }) => {
              setLoading(false)
              messageApi.success({ content: `Updated question label`, duration: 3 })
              updateLabelOfGivenQuestion(question._id, value)
              setQuestion({ ...question, label: value })
            })
            .catch(err => {
              setLoading(false)
              messageApi.error({ content: `Error: ${err}`, duration: 3 })
            })
        }}
        options={[
          { value: 0, label: "Incomplete" },
          { value: 1, label: "Complete" },
          { value: 2, label: "Doubt" },
          { value: 3, label: "Again" },
        ]}
      />] : [],
    <Radio.Group
      key="showAnswer"
      optionType="button"
      buttonStyle="solid"
      value={showQuestion ? (showAnswer ? 2 : 0) : (showAnswer ? 1 : 0)}
      options={smallScreen ? [
        { label: "Question", value: 0 },
        { label: "Answer", value: 1 },
      ] : [
        { label: "Question", value: 0 },
        { label: "Answer", value: 1 },
        { label: "Question & Answer", value: 2 }
      ]}
      onChange={e => {
        switch (e.target.value) {
          case 0:
            setShowQuestion(true)
            setShowAnswer(false)
            break
          case 1:
            setShowQuestion(false)
            handleShowAnswer()
            break
          case 2:
            setShowQuestion(true)
            handleShowAnswer()
            break
        }
      }}
    />,
    ...(isAuthenciacted() && !isMobile && !smallScreen) ?
      [<Button
        danger={questionInList}
        key="addToGenerateList"
        onClick={() => (questionInList ? removeFromQuestionList(question) : appendToQuestionList(question))}
      >
        {questionInList ? "Remove from Generator" : "Add to Generator"}
      </Button>] : [],
  ]

  useEffect(() => {
    setLoading(true)

    getQuestions({
      questionBank,
      query: {
        label: label,
        subject: subject,
        properties: properties,
        orderBy: orderBy,
        page: page,
      }
    }).then(({ data, total }) => {
      // console.log(data, total);
      setQuestions(data)
      setTotal(total)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      messageApi.error({ content: `Error: ${err}`, duration: 3 })
    })
  }, [questionBank, label, examId, subject, properties, orderBy, page, messageApi])

  if (!(questionBank in QuestionBank)) {
    console.error(`Question bank ${questionBank} not found.`)
    return <ErrorPage />
  }

  return (
    <>
      <Layout.Content className="site-layout-background" style={{ paddingBottom: "30px" }}>
        <Space
          direction="horizontal"
          align="start"
          style={{
            columnGap: 15,
            display: "flex",
            margin: "auto",
            justifyContent: "center",
            maxWidth: "90vw",
          }}
        >
          <Space
            direction="vertical"
            style={{
              display: "flex",
              margin: "auto",
              marginTop: 80,
              width: smallScreen ? "90vw" : "min(21cm, 90vw)",
              // width: "21cm",
              // maxWidth: "max(60vw, 380px)",
            }}
          >
            <Selector questionBank={questionBank} loading={loading} exams={useMyExams && myExams.length > 0 ? myExams : exams} setQuery={handleQuery} />
            <Row justify="space-between" gutter={[0, 10]}>
              {
                isAuthenciacted() &&
                <Col>
                  <Select
                    key="label"
                    defaultValue={label}
                    style={{ width: 115, textAlign: "center" }}
                    onChange={(value) => setLabel(value)}
                    options={[
                      { value: -1, label: "All" },
                      { value: 0, label: "Incomplete" },
                      { value: 1, label: "Complete" },
                      { value: 2, label: "Doubt" },
                      { value: 3, label: "Again" },
                    ]}
                  />
                </Col>
              }
              <Row justify="space-between" gutter={[10, 10]}>
                <Col><OrderByButton field="year" orderBy={orderBy} setOrderBy={setOrderBy} /></Col>
                <Col><OrderByButton field="paper" orderBy={orderBy} setOrderBy={setOrderBy} /></Col>
                <Col><OrderByButton field="mark" orderBy={orderBy} setOrderBy={setOrderBy} /></Col>
              </Row>
              <Space>
                {
                  myExams.length > 0 &&
                  <Checkbox checked={useMyExams} onChange={(e) => setUseMyExams(e.target.checked)}>
                    My Subjects
                  </Checkbox>
                }
              </Space>
            </Row>
            <List
              itemLayout="vertical"
              size="large"
              split={false}
              rowKey="_id"
              loading={loading}
              pagination={{
                onChange: (page) => setPage(page),
                current: page,
                pageSize: PageSize,
                showSizeChanger: false,
                total: total,
              }}
              dataSource={questions}
              renderItem={(q) => (
                <List.Item className="question-card">
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Question
                      fullScreenButton
                      data={q}
                      onClick={() => {
                        // console.log(q)
                        setShowQuestion(true)
                        setShowAnswer(false)
                        setOpen(true)
                        setAnswerLoaded(false)
                        setQuestion(q)
                      }}
                    />
                    <Row justify="end">
                      <Space>
                        {(q.label >= 0) ?
                          <Select
                            size={buttonSize}
                            value={q.label}
                            onChange={(value) => {
                              setLoading(true)
                              setQuestionLabel({ questionBank, questionId: q._id, label: value })
                                .then(({ result }) => {
                                  setLoading(false)
                                  messageApi.success({ content: `Updated question label`, duration: 3 })
                                  updateLabelOfGivenQuestion(q._id, value)
                                })
                                .catch(err => {
                                  setLoading(false)
                                  messageApi.error({ content: `Error: ${err}`, duration: 3 })
                                })
                            }}
                            options={[
                              { value: 0, label: "Incomplete" },
                              { value: 1, label: "Complete" },
                              { value: 2, label: "Doubt" },
                              { value: 3, label: "Again" },
                            ]}
                          />
                          : <></>
                        }
                        {
                          !isMobile && !smallScreen &&
                          <Button size={buttonSize} onClick={() => appendToQuestionList(q)}>
                            Generator
                          </Button>
                        }
                        <Button size={buttonSize} loading={loadingAnswer && (q._id === qidOnLoading)} onClick={() => {
                          // console.debug("loading answer")
                          if (!isAuthenciacted()) {
                            messageApi.error({ content: "Unauthorized access to view answer, please sign in.", duration: 3 })
                            return
                          };

                          setShowQuestion(false)
                          if (answerLoaded && (q._id === question?._id)) {
                            setShowAnswer(true)
                            setOpen(true)
                          } else {
                            setLoadingAnswer(true)
                            setQidOnLoading(q._id)
                            getQuestionById(questionBank, q?._id!).then(({ result }) => {
                              // console.debug(question?._id)
                              const { success, data } = result
                              const { _id, label } = data
                              // console.debug(result)
                              if (success) {
                                setLoadingAnswer(false)
                                setQuestion(data)
                                updateLabelOfGivenQuestion(_id, label)
                                setAnswerLoaded(true)
                                setShowAnswer(true)
                                setOpen(true)
                              } else {
                                messageApi.error({
                                  content: data,
                                  duration: 3,
                                })
                                setLoadingAnswer(false)
                                setAnswerLoaded(true)
                              }
                            })
                          }
                        }}>
                          Answer
                        </Button>
                      </Space>
                    </Row>
                  </Space>
                </List.Item>
              )}
            />
          </Space>
          {/* List of question used to generate paper */}
          <QuestionList messageApi={messageApi} questionBank={questionBank} questionList={questionList} setQuestionList={setQuestionList} />
        </Space>
      </Layout.Content >
      <Modal
        bodyStyle={{ height: "calc(85vh - 50px)", overflowY: "scroll", width: "100%" }}
        centered
        open={open}
        onCancel={() => {
          setOpen(false)
          setShowAnswer(false)
        }}
        width={showAnswer && showQuestion ? "min(calc(42cm + 50px), 90vw)" : "min(calc(21cm + 50px), 90vw)"}
        footer={<Space>{modalButtons}</Space>}
      >
        <Question splitView={{ showAnswer, showQuestion }} showAnswer={showAnswer} isMobile={smallScreen} data={question!} />
      </Modal>
    </>
  )
}

export { Questions }
