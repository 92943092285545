const re = /^\d+/

const sort = (a: string, b: string) => {
  const regexA = a.match(re)
  const regexB = b.match(re)
  const regexAisValid = regexA !== null
  const regexBisValid = regexB !== null
  // console.log(a, regexA, b, regexB);
  if (regexAisValid && regexBisValid) {
    // console.log(a, Number(regexA[0]), b, Number(regexB[0]));
    return Number(regexA[0]) - Number(regexB[0])
  } else if (regexAisValid && !regexBisValid) {
    return -1
  } else if (!regexAisValid && regexBisValid) {
    return 1
  } else {
    return 0
  }
}

export { sort }
