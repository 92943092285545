import { FC, useState, useEffect } from "react"
import { Layout, Space, Col, Row, Statistic, } from "antd"
import { useNavigate } from "react-router-dom"
import { MessageInstance } from "antd/es/message/interface"

import { isAuthenciacted } from "../../utils/apis"
import { getUsage } from "../../utils/apis"

const { Content } = Layout

interface TUsage {
  answers: {
    used: number,
    total: number
  },
  papers: {
    used: number,
    total: number
  }
  endTime: string,
  next: string
}

const Usage: FC<{ messageApi: MessageInstance }> = ({ messageApi }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [usage, setUsage] = useState<TUsage>({ answers: { used: 0, total: 0 }, papers: { used: 0, total: 0 }, endTime: "--", next: "--" })

  useEffect(() => {
    const key = Date.now()
    if (!isAuthenciacted()) navigate("/")
    else {
      messageApi.open({
        key,
        type: "loading",
        content: "Loading usage...",
        duration: 0,
      })
      setLoading(true)

      getUsage().then(({ result: { success, data } }) => {
        // console.log(data)
        if (success) {
          messageApi.open({
            key,
            type: "success",
            content: "Usage loaded.",
            duration: 3,
          })
          setUsage(data)
        } else {
          messageApi.open({
            key,
            type: "error",
            content: "Error: cannot load usage.",
            duration: 3
          })
          console.error(data)
        }
        setLoading(false)
      }).catch(err => {
        messageApi.open({
          key,
          type: "error",
          content: "Error: cannot load usage.",
          duration: 3
        })
      })
    }
  }, [])

  const now = new Date()
  const next = new Date(usage.next)
  const endTime = new Date(usage.endTime)

  return (
    <Content className="site-layout-background" style={{ paddingBottom: "30px" }}>
      <Space
        direction="horizontal"
        align="start"
        style={{
          columnGap: 15,
          display: "flex",
          margin: "auto",
          justifyContent: "center",
          maxWidth: "90vw",
        }}
      >
        <Space
          direction="vertical"
          style={{
            display: "flex",
            margin: "auto",
            marginTop: 80,
            padding: "1em",
            width: "21cm",
            maxWidth: "max(60vw, 380px)",
          }}
        >
          <Row gutter={16} wrap>
            <Col span={12}>
              <Statistic loading={loading} title="Answers" value={usage.answers.used} suffix={`/ ${Math.max(usage.answers.total, 0)}`} />
            </Col>
            <Col span={12}>
              <Statistic loading={loading} title="Worksheet" value={usage.papers.used} suffix={`/ ${Math.max(usage.papers.total, 0)}`} />
            </Col>
            <Col span={12}>
              <Statistic loading={loading} title="Next Refresh" value={next.toLocaleString()} />
            </Col>
            <Col span={12}>
              {(Number(usage.endTime) === -1) ? null :
                <Statistic loading={loading}
                  title={endTime > now ? "Your memebership will expire at" : 'Your memebership is expired since'}
                  valueStyle={{ color: endTime > now ? '#3f8600' : '#cf1322' }}
                  value={endTime.toLocaleString()} />}
            </Col>
          </Row>
        </Space>
      </Space>
    </Content>
  )
}

export { Usage }
