import React, { useState, useEffect, FC } from "react"
import { Typography, Layout, Space, Table, Switch, Button } from "antd"
import { useNavigate } from "react-router-dom"
import { MessageInstance } from "antd/es/message/interface"
import { Bar } from '@ant-design/plots'

import { isAuthenciacted, firstSignInCheck, getUserProgress, getTags } from "../../utils/apis"

import type { ColumnsType } from 'antd/es/table'
import { QuestionBank } from "../../types/query.d"

const { Title, Text } = Typography
const { Content } = Layout

interface ProgressType {
  key: number | string,
  exam?: string,
  subject?: string,
  chapter?: string,
  incompleted: number,
  completed: number,
  doubt: number,
  again: number,
  chapters?: ProgressType[],
}

interface BarDataType {
  id: string,
  type: string,
  value: number,
}

const columns = (
  showIncompleted: boolean,
  exams: Exam[],
  setPorgress: (p: ProgressType) => void
): ColumnsType<ProgressType> => [
    {
      title: 'ExamBoard',
      dataIndex: 'exam',
      key: 'exam',
      render: (text, record) => {
        const exam = exams.find(({ _id }) => record.exam === _id)
        const { name } = exam || {}
        return <>{name}</>
      },
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      render: (text, record) => {
        const exam = exams.find(({ _id }) => record.exam === _id)
        const { subjects = [] } = exam || {}
        const subject = subjects.find(({ _id }) => record.subject === _id)
        const { name } = subject || {}
        return <>{name}</>
      }
    },
    ...(showIncompleted ? [{
      title: 'Incompleted',
      dataIndex: ['incompleted'],
      key: 'incompleted',
      width: 80,
    }] : []),
    {
      title: 'Completed',
      dataIndex: ['completed'],
      key: 'completed',
      width: 80,
    },
    {
      title: 'Doubt',
      dataIndex: ['doubt'],
      key: 'doubt',
      width: 80,
    },
    {
      title: 'Again',
      dataIndex: ['again'],
      key: 'again',
      width: 80,
    },
    {
      title: "Action",
      key: "action",
      width: 80,
      render: (text, record) =>
        <Button type="link" size="small" disabled={!subjectHasChapters(record, exams)}
          onClick={() => {
            // console.debug(record)
            setPorgress(record)
          }}
        >View</Button>,
    }
  ]

const chapterColumns = (showIncompleted: boolean): ColumnsType<ProgressType> => [
  {
    title: '',
    dataIndex: 'key',
    rowScope: 'row',
  },
  {
    title: 'Chapter',
    dataIndex: 'chapter',
    key: 'chapter'
  },
  ...(showIncompleted ? [{
    title: 'Incompleted',
    dataIndex: ['incompleted'],
    key: 'incompleted',
    width: 80,
  }] : []),
  {
    title: 'Completed',
    dataIndex: ['completed'],
    key: 'completed',
    width: 80,
  },
  {
    title: 'Doubt',
    dataIndex: ['doubt'],
    key: 'doubt',
    width: 80,
  },
  {
    title: 'Again',
    dataIndex: ['again'],
    key: 'again',
    width: 80,
  },
]

const subjectHasChapters = (record: ProgressType, exams: Exam[] = []): boolean => {
  const { exam = "", subject: s = "" } = record
  const examBoard = exams.find(({ _id }) => _id === exam)
  const { subjects = [] } = examBoard || {}
  const subject = subjects.find(({ _id }) => _id === s)
  const { tags = [] } = subject || {}
  const chapter = tags.find(({ name }) => name === "chapter")
  const { type = "string", options = [] } = (chapter || {}) as TagStr | TagMul
  if (type === "string") {
    return options.length > 0
  } else if (type === "multi-level") {
    return Object.values(options).length > 0
  }
  return false
}

const progressToBarData = (
  { showIncompleted, progress = { key: "", incompleted: 0, completed: 0, again: 0, doubt: 0 } }:
    { showIncompleted: boolean, progress: ProgressType }
): BarDataType[] => {
  const results: BarDataType[] = []
  const { chapters = [] } = progress
  chapters.forEach(
    ({ key, chapter, completed = 0, doubt = 0, again = 0, incompleted = 0 }) => {
      const id = `${key} ${chapter}`
      results.push({
        id,
        type: 'Completed',
        value: completed,
      })
      if (!showIncompleted)
        results.push({
          id,
          type: 'Incompleted',
          value: incompleted,
        })
      results.push({
        id,
        type: 'Doubt',
        value: doubt,
      })
      results.push({
        id,
        type: 'Again',
        value: again,
      })
    })
  // console.debug(results)
  return results
}

const filterOutZeroProgress = (progress: ProgressType[] = []): ProgressType[] =>
  progress.filter(({ completed = 0, doubt = 0, again = 0 }) => (completed + doubt + again) > 0)
    .map(({ chapters, ...rest }) => {
      if (chapters) {
        return { ...rest, chapters: filterOutZeroProgress(chapters || []) }
      } else {
        return { ...rest }
      }
    })

const Progress: FC<{ messageApi: MessageInstance }> = ({ messageApi }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [tags, setTags] = useState<Exam[]>([])
  const [useMyExams, setUseMyExams] = useState<boolean>(true)
  const [showProgressThisWeek, setShowProgressThisWeek] = useState<boolean>(false)
  const [progress, setProgress] = useState<ProgressType[]>([])
  const [myProgress, setMyProgress] = useState<ProgressType[]>([])
  const [progressLastWeek, setProgressLastWeek] = useState<ProgressType[]>([])
  const [myProgressLastWeek, setMyProgressLastWeek] = useState<ProgressType[]>([])

  const [progressOfSelectedSubject, setProgressOfSelectedSubject] = useState<ProgressType>()

  useEffect(() => {
    const key = Date.now()
    setLoading(true)
    if (!isAuthenciacted()) navigate("/")
    else {
      messageApi.open({
        key,
        type: "loading",
        content: "Loading...",
        duration: 3,
      })
      firstSignInCheck(navigate)
        .then(({ subjects }) => {
          Promise.all([getUserProgress(true), getUserProgress(false), getTags({ questionBank: QuestionBank.Topical })])
            .then(([resProgressLastWeek, resProgress, resTags]) => {
              const { success } = resProgress.result

              if (!success) {
                setLoading(false)
                messageApi.error({
                  key,
                  content: "Error loading progress",
                  duration: 3,
                })
              }

              // console.debug(resProgressLastWeek.result)
              // console.debug(
              //   resProgressLastWeek.result.data,
              //   filterOutZeroProgress(resProgressLastWeek.result.data),
              //   filterOutZeroProgress(resProgressLastWeek.result.data)
              //     .filter(({ subject = "" }) =>
              //       subjects.map(([, s]) => s === subject).includes(true)))
              setProgressLastWeek(filterOutZeroProgress(resProgressLastWeek.result.data))
              setMyProgressLastWeek(
                filterOutZeroProgress(resProgressLastWeek.result.data)
                  .filter(({ subject = "" }) =>
                    subjects.map(([, s]) => s === subject).includes(true))
              )
              setProgress(resProgress.result.data)
              setMyProgress(
                resProgress.result.data
                  .filter(({ subject = "" }) =>
                    subjects.map(([, s]) => s === subject).includes(true))
              )
              setTags(resTags)
              messageApi.open({
                key,
                type: "success",
                content: "Successfully loaded!",
                duration: 3,
              })
              setLoading(false)
            })
        })
        .catch((err) => {
          console.error(err)
          messageApi.open({
            key,
            type: "error",
            content: "Error encountered, please sign in again.",
            duration: 3,
          })
        })
    }
  }, [messageApi, navigate])

  const data = showProgressThisWeek ?
    (useMyExams ? myProgressLastWeek : progressLastWeek)
    : (useMyExams ? myProgress : progress)

  // console.debug("data", data, myProgress)

  return (
    <Content className="site-layout-background" style={{ paddingBottom: "30px" }}>
      <Space
        direction="horizontal"
        align="start"
        style={{
          columnGap: 15,
          display: "flex",
          margin: "auto",
          justifyContent: "center",
          maxWidth: "90vw",
        }}
      >
        <Space
          direction="vertical"
          style={{
            display: "flex",
            margin: "auto",
            marginTop: 80,
            padding: "1em",
            width: "80vw",
          }}
        >
          <Title level={2}>My Progress</Title>
          <Space direction="horizontal">
            <Switch size="small" checked={showProgressThisWeek} onChange={(checked) => {
              setShowProgressThisWeek(checked)
              setProgressOfSelectedSubject(undefined)
            }} />
            <Text>Progress in This Week</Text>
          </Space>
          <Table
            expandable={{
              expandedRowRender: ({ chapters = [] }) => {
                // console.debug(chapters)
                return <Table bordered columns={chapterColumns(!showProgressThisWeek)} dataSource={chapters} pagination={false} />
              },
              rowExpandable: (record) => subjectHasChapters(record, tags)
            }}
            pagination={false}
            loading={loading}
            bordered
            columns={columns(!showProgressThisWeek, tags, setProgressOfSelectedSubject)}
            dataSource={data}
          />
          {
            progressOfSelectedSubject ?
              <Bar
                data={progressToBarData({
                  showIncompleted: showProgressThisWeek,
                  progress: progressOfSelectedSubject
                })}
                // label={{
                //   position: 'left',
                // }}
                isStack
                isPercent={!showProgressThisWeek}
                loading={loading}
                xField={'value'}
                yField={'id'}
                seriesField='type' />
              : <></>
          }
        </Space>
      </Space>
    </Content>
  )
}

export { Progress }
